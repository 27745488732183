import { Button, Tooltip } from "@mui/material";
import Iconify from "src/components/Iconify";
import Translate from "src/components/translate";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";
import { WINGOLD_URL } from "src/config";
import Logo from "src/images/wingold-default.webp";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const userEmail = user?.email;

  const onSubmit = async () => {
    setLoading(true);
    const reqData = new FormData();
    reqData.append("email", userEmail);
    try {
      const { status, data } = await axiosInstance.post(
        `api/login-to-wg`,
        reqData
      );
      if (status === 200) {
        window.open(
          `${WINGOLD_URL}login-wingold?public_key=${data.second_project_response.public_key}`
        );
        setLoading(false);
      }
    } catch (err) {
      enqueueSnackbar(err.response?.data?.message || err.message, {
        variant: "error",
      });
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingButton
        loading={loading}
        variant="text"
        size="small"
        startIcon={<Iconify icon="clarity:switch-line" color="#d09f4b" />}
        endIcon={
          <>
            <img src={Logo} style={{ width: 50 }} />
          </>
        }
        onClick={onSubmit}
      />
    </>
  );
};

export default Index;
