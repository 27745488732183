import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Investments = Loadable(
  lazy(() => import("src/pages/admin/investment/investments/index"))
);
const History = Loadable(
  lazy(() => import("src/pages/admin/investment/history/index"))
);

const investments = [
  {
    path: "investments",
    children: [
      { element: <Navigate to="investments" />, index: true },
      { path: "investments", element: <Investments /> },
      { path: "history", element: <History /> },
    ],
  },
];

export default investments;
